<template>
  <div class="paginador">
    <ul class="pagination pagination-rounded justify-content-end mt-2 mb-5">
      <li class="page-item" :class="{disabled: pag == 1}">
        <a 
          href="javascript: void(0);"
          class="page-link d-none d-sm-block"
          @click="cargarPagina(1)"
        >
          <i class="mdi mdi-chevron-double-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{disabled: pag == 1}">
        <a 
          href="javascript: void(0);"
          class="page-link"
          @click="cargarPagina(pag - 1)"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li 
        v-for="n in totpag"
        :key="n"
        class="page-item"
        :class="{ active: n == pag }"
        v-show="btnPagIni <= n && n <= btnPagFin"
      >
        <a 
          v-bind:data-page="n"
          href="javascript: void(0);"
          @click="cargarPagina(n)"
          class="page-link"
        >
          {{ n }}
        </a>
      </li>
      <li class="page-item" :class="{ disabled: pag == totpag }">
        <a 
          href="javascript: void(0);"
          class="page-link"
          @click="cargarPagina(pag + 1)"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
      <li class="page-item" :class="{ disabled: pag == totpag }">
        <a 
          @click="cargarPagina(totpag)"
          href="javascript: void(0);"
          class="page-link d-none d-sm-block"
        >
          <i class="mdi mdi-chevron-double-right"></i>
        </a>
      </li>
      <li class="page-item">
        <select
          title="Cantidad de elementos por página"
          v-model="eppselec"
          class="form-select"
          @change="cambioepp()"
        >
          <option v-for="n in eppaux" :key="n" :value="n">
            {{ n }}
          </option>
        </select>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Paginador',
  props: {
    // página
    pag: {
      type: Number,
      default: 0
    },
    // total de páginas
    totpag: {
      type: Number,
      default: 0
    },
    // Elementos por página
    epp: {
      type: Number,
      default: 0
    },
    eppmin: Number, // elementos por página minimo
    eppmax: Number // elementos por página máximo
  },
  data() {
    return {
      btnPagIni: 1,
      btnPagFin: 5,
      eppaux: [],
      eppselec: 0
    }
  },
  watch: {
    pag: function(val) {
      this.calcularRangoBotonesPagina()
    },
    totpag: function(val) {
      this.calcularRangoBotonesPagina()
    }
  },
  created: function() {
    this.calcularRangoBotonesPagina()

    if (this.eppmin == undefined || this.eppmax == undefined) {
      this.eppaux = [10, 20, 30, 40, 50, 100]
    } else {
      for (let i = this.eppmin; i <= this.eppmax; i += this.eppmin) {
        this.eppaux.push(i)
      }
    }

    if (this.epp != 0) {
      this.eppselec = this.epp
    } else {
      if (this.eppaux.length > 0) this.eppselec = this.eppaux[0]
    }
  },
  methods: {
    calcularRangoBotonesPagina: function() {
      var self = this
      let pag = parseInt(self.pag),
        totpag = parseInt(self.totpag)

      self.btnPagIni = self.pag - 2
      if (self.pag >= totpag - 2) self.btnPagIni = totpag - 4
      if (self.btnPagIni < 1) self.btnPagIni = 1

      self.btnPagFin = self.pag + 2
      if (self.pag >= 1 && self.pag <= 3) self.btnPagFin = 5
      if (self.btnPagFin > totpag) self.btnPagFin = totpag
    },

    cargarPagina: function(n) {
      if (n <= 0) n = 1
      if (n > this.totpag) n = this.totpag
      this.$emit('cargar-pagina', n)
    },

    cambioepp: function() {
      this.$emit('epp-actualizado', this.eppselec)
    }
  },
}
</script>

<style scoped>
.paginador {
  display: inline-block;
  padding: 0px;
  line-height: 28px;
}
.paginador > ul > li > select {
  height: 35px;
} 
</style>