<template>
  <div class="btn-group" style="width: 100%;">
    <div class="input-group" style="width: 100%;">
      <input
        name="dato"
        type="text"
        class="form-control"
        v-model="dato"
        :placeholder="placeholder"
        @keyup.enter="datoActualizado()"
        onkeypress="return check(event, 'text')"
      />
      <button class="btn btn-success" type="button" @click="datoActualizado()">
        <span class="d-none d-sm-block">
          Filtrar
          <i class="bx bx-filter-alt"></i>
        </span>
        <span class="d-block d-sm-none">
          <i class="bx bx-filter-alt"></i>
        </span>
      </button>
    </div>
    <div class="btn-group">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'datoini'],
  data: function() {
    return {
      dato: ''
    }
  },
  created: function() {
    this.dato = this.datoini != '' ? this.datoini : ''
  },
  methods: {
    datoActualizado: function() {
      this.$emit('dato-actualizado', this.dato)
    }
  }
}
</script>

