<template>
  <Layout tituloPagina="Hotspots | Hotspots">
    <div class="row" style="margin-bottom: 20px">
      <div class="offset-md-2 col-md-8 col-sm-8 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarHotspots()
          "
          :datoini="dato"
          placeholder="Dato del hotspot"
        ></filtrador>
      </div>
      <div class="col-md-2 text-right col-sm-4 col-3">
        <router-link class="btn btn-success" :to="{ name: 'nuevoHotspot' }">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Listado de hotspots
          <small class="font-size-10">
            Aquí puedes ver los hotspots registrados
          </small>
        </h4>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="tblRouters table table-hover table-nowrap" id="tabla-hotspot">
            <thead>
              <tr>
                <th>#ID</th>
                <th>Tipo de autenticación</th>
                <th style="width: 200px">Nombre</th>
                <th>Router asignado</th>
                <th>LAN</th>
                <th>Descripción</th>
                <th>Estado</th>
                <!-- <th style="width: 130px">Host conectados</th> -->
                <th class="text-center" style=" width: 180px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="hotspot in hotspots" :key="hotspot.id">
                <td class="fw-semibold">{{ hotspot.id }}</td>
                <td>
                  <span
                    :class="{
                      'text-success' : hotspot.tipo_autenticacion == 1,
                      'text-info' : hotspot.tipo_autenticacion == 2
                    }"
                  >
                    <strong>
                      {{hotspot.tipo_autenticacion == 1 ? 'Solo PIN':'Usuario y contraseña'}}
                    </strong>
                  </span>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'edicionHotspot',
                      params: { id: hotspot.id }
                    }"
                  >
                    {{ hotspot.nombre }}
                  </router-link>
                </td>
                <td :class="hotspot.router ? '' : 'text-warning'">
                  {{ hotspot.router ? hotspot.router.nombre : 'Router sin asignar' }}
                </td>
                <td>
                  {{ hotspot.lan ? hotspot.lan.descripcion : '' }}
                </td>
                <td>
                  {{ hotspot.descripcion }} <!-- <br /><br />
                  <span v-if="hotspot.sitio != null">
                    <strong>Sitio:</strong> {{ hotspot.sitio.nombre }}
                  </span> -->
                </td>
                <td>
                  <span class="badge bg-gradient font-size-12"
                    :class="{
                      'bg-primary' : hotspot.en_linea,
                      'bg-danger' : !hotspot.en_linea
                    }"
                  >
                    {{hotspot.en_linea ? 'Online':'Offline'}}
                  </span>
                </td>
                <!-- <td class="text-center">
                  <span class="badge">
                    0
                  </span>
                </td> -->
                <td class="text-center">
                  <router-link
                    :to="{ name: 'edicionHotspot', params: { id: hotspot.id } }"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>

                  <button
                    class="btn btn-danger btn-sm"
                    @click="eliminarHotspot(hotspot)"
                  >
                    <i class="mdi mdi-trash-can-outline"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarHotspots()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'

import HotspotsSrv from '@/services/hotspot/HotspotsSrv.js'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoHotspots',
  components: {
    Layout,
    Filtrador,
    Paginador
  },
  data() {
    return {
      baseUrl: window.location.origin,
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoHotspots.por_pagina') || 10),
      tc: 0, // Total de portales cautivos
      totpag: 0, // Total de páginas
      hotspots: []
    }
  },

  created: function() {
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)

    self.refrescarHotspots()
  },

  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarHotspots({ page: n })
    },

    eliminarHotspot: function(hotspot) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "¿Eliminar el hotspot?",
        html: "¿Está seguro de eliminar el hotspot <br><b>"+hotspot.nombre+"</b>?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          HotspotsSrv.eliminar(hotspot.id).then(response => {
            swal.fire("Eliminado!", "Hotspot "+hotspot.nombre+" eliminado.", "success");
            self.refrescarHotspots()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo eliminar el hotspot "+hotspot.nombre
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    
    refrescarHotspots: function() {
      var self = this

      iu.spinner.mostrar('#tabla-hotspot')

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato
      }

      HotspotsSrv.hotspotsJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoHotspots.por_pagina', self.epp)
        let page = response.data
        self.hotspots = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarHotspots()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los hostpots'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-hotspot')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-hotspot')
  }
}
</script>

<style scoped>

</style>